.footer {
    height: 50px;
    display: flex;
    flex-direction: column;
    background-color: #2f4050;
  }
.text-content {
    /* color: #f8f9fa52; */
    text-align: center;
    margin: 10px;
    font-weight: bold;
    font-family: cursive;
  }
  
  .icon-btn {
    height: 25px;
  }
  .icon-btn:hover {
    color: #1ab394 !important;
  }
  
  .icons-list {
    display: flex;
    /* gap: 30px; */
    justify-content: space-evenly;
  }
  .icon {
    width: 30px;
    cursor: pointer;
    font-size: 20px;
    color: #f8f9fa52;
  }